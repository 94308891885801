import React,{useState, useEffect} from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo.jsx"
import "../styles/page.css"
import { Helmet } from 'react-helmet';
import { useTheme } from '../context/ThemeContext';
import planPrice from '../contents/price/planPrice.json';

export const Head = () => (
  <SEO 
  title="STAIRAZ Payment: Empower Your Future with the Latest AI-Based Tools at the Lowest Cost"
  description="Discover flexible, affordable plans to unlock advanced AI optimization features. Choose the perfect plan for your needs and start optimizing today."
  pathname="/payment/"
  

      >
        <html lang="en" />
 <meta name="keywords" content="AI-Powered Service, Job Application, Cover Letter Generators, Free Tools, STAIRAZ"/>
  </SEO>
);

// const computeCombinedPrice = (pricingResume, pricingCoverLetter, frequency) => {
//   const resumeOriginal = parseFloat(pricingResume[frequency].original.replace('$', ''));
//   const coverLetterOriginal = parseFloat(pricingCoverLetter[frequency].original.replace('$', ''));
//   // const resumeDiscount = parseFloat(pricingResume[frequency].discount.replace('$', ''));
//   const coverLetterDiscount = parseFloat(pricingCoverLetter[frequency].discount.replace('$', ''));

//   // const totalOriginal = ((resumeOriginal + coverLetterOriginal)*0.8).toFixed(2);
//   // const totalDiscount = ((resumeDiscount + coverLetterDiscount)*0.8).toFixed(2);

//   let combinedTotal = null;
//   if (pricingResume[frequency].total && pricingCoverLetter[frequency].total) {
//     const resumeTotal = parseFloat(pricingResume[frequency].total.replace('$', '').split('/')[0]);
//     const coverLetterTotal = parseFloat(pricingCoverLetter[frequency].total.replace('$', '').split('/')[0]);
//     combinedTotal = ((resumeTotal + coverLetterTotal)*0.8).toFixed(2);
//   }

//   return {
//     original: `$${totalOriginal}`,
//     discount: `$${totalDiscount}`,
//     unit: pricingResume[frequency].unit,
//     total: combinedTotal ? `$${combinedTotal}/${pricingResume[frequency].unit.split('/')[1]}` : null
//   };
// };





const AboutUs = ({data}) => {
  const { isDarkMode } = useTheme();
  const [paymentFrequencyResume, setPaymentFrequencyResume] = useState('halfYearly');
  const [paymentFrequencyCoverLetter, setPaymentFrequencyCoverLetter] = useState('halfYearly');
  const [paymentFrequencyBundle, setPaymentBundle] = useState('halfYearly');
  const [currentSection, setCurrentSection] = useState('resume'); 

  const { pricingResume, pricingCoverLetter } = planPrice;
  // const [combinedPrice, setCombinedPrice] = useState(computeCombinedPrice(pricingResume, pricingCoverLetter, paymentFrequencyBundle));

  // useEffect(() => {
  //   setCombinedPrice(computeCombinedPrice(pricingResume, pricingCoverLetter, paymentFrequencyBundle));
  //   console.log(combinedPrice)
  // }, [paymentFrequencyBundle, pricingResume, pricingCoverLetter]);

  const handleFrequencyChangeBundle = (frequency) => {
    setPaymentBundle(frequency);
  };
  

  const handleFrequencyChange = (frequency) => {
    setPaymentFrequencyResume(frequency);
  };

  const handleFrequencyChangeCoverLetter = (frequency) => {
    setPaymentFrequencyCoverLetter(frequency);
  };

  const getLookupKeyResume = (frequency) => {
    switch (frequency) {
      case 'monthly':
        return 'resume_standard_monthly';
      case 'quarterly':
        return 'resume_standard_quarterly';
      case 'halfYearly':
        return 'resume_standard_half_yearly';
      case 'yearly':
        return 'resume_standard_yearly';
      default:
        return 'resume_standard_monthly';
    }
  };

  const getLookupKeyCoverLetter = (frequency) => {
    switch (frequency) {
      case 'monthly':
        return 'coverLetter_standard_monthly';
      case 'quarterly':
        return 'coverLetter_standard_quarterly';
      case 'halfYearly':
        return 'coverLetter_standard_half_yearly';
      case 'yearly':
        return 'coverLetter_standard_yearly';
      default:
        return 'coverLetter_standard_monthly';
    }
  };

  const getCombinedLookupKeys = (frequency) => {
    const resumeKey = getLookupKeyResume(frequency);
    const coverLetterKey = getLookupKeyCoverLetter(frequency);
    return `${resumeKey},${coverLetterKey}`;
  };

  const handleCheckoutBundle = () => {
    const combinedLookupKeys = getCombinedLookupKeys(paymentFrequencyResume);
    const prodIds = "prod_QEwFcseH0KpCv0,prod_QWuH5caA6hB1B1";// resume builder + cover Letter
    const paymentMode = "subscription";
    window.location.href = `/checkout-redirect?lookup_keys=${combinedLookupKeys}&payment_mode=${paymentMode}&prod_ids=${prodIds}`;
  
  };

  const handleCheckoutResume = () => {
    const lookupKey = getLookupKeyResume(paymentFrequencyResume);
    window.location.href = `/checkout?lookup_key=${lookupKey}&payment_mode=subscription&prod_id=prod_QEwFcseH0KpCv0`;
  };
  const handleCheckoutResumeFlex = () => {
    window.location.href = `/career/resume-builder-ai/checkout-redirect?lookup_key=resume_standard_flexible_monthly&payment_mode=flexible`;
  };

  const handleCheckoutCoverLetter = () => {
    const lookupKey = getLookupKeyCoverLetter(paymentFrequencyCoverLetter);
    window.location.href = `/career/cover-letter-ai/checkout-redirect?lookup_key=${lookupKey}&payment_mode=subscription`;
  };
  const handleCheckoutCoverLetterFlex = () => {
    window.location.href = `/career/cover-letter-ai/checkout-redirect?lookup_key=coverLetter_standard_flexible_monthly&payment_mode=flexible`;
  };

  

  
  useEffect(() => {
    const link = document.getElementById('mode-stylesheet');
    if (link) {
      const additionalLink = document.createElement('link');
      additionalLink.rel = 'stylesheet';
      additionalLink.href = isDarkMode ? '/styles/page.css' : '/styles/pageLight.css';
      document.head.appendChild(additionalLink);

      return () => {
        document.head.removeChild(additionalLink);
      };
    }
  }, [isDarkMode]);

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Get hash without '#'
    if (hash) {
      setCurrentSection(hash);
    }
  }, []);

  
  return (
    <Layout>
{/* 
    <section className="section">
    <div className="switch-buttons-container"> */}
    {/* <div className="bundle-container">
      <button className={`switch-button ${currentSection === 'bundle' ? 'active' : ''}`} onClick={() => setCurrentSection('bundle')}>
        Bundle
      </button>
      <span className="bundle-small-text">additional 20% off</span>
    </div> */}
      {/* <button className={`switch-button ${currentSection === 'resume' ? 'active' : ''}`} onClick={() => setCurrentSection('resume')}>Resume Builder</button> */}
      {/* <button className={`switch-button ${currentSection === 'coverLetter' ? 'active' : ''}`}  onClick={() => setCurrentSection('coverLetter')}>Cover Letter</button> */}
    {/* </div>
    </section> */}
    {/* {currentSection === 'bundle' && (
      <section className="section" id="bundle">
        <h1 className="section-heading">STAIRAZ Service Bundle</h1>
        <p className="section-content">
        Experience the ultimate job application boost with the STAIRAZ Service Bundle. By combining our cutting-edge Resume Builder and intelligent Cover Letter AI, you can significantly enhance your job-seeking potential and increase your chances of landing your dream job—all at a lower cost.
      </p>
        <section className="section-plan" id="plan-section">
          <div className="plan-container">
            <div className="plan-panel-bundle">
              <h2 className="plan-name">Subscription: Resume Builder + Cover Letter</h2>
              <h3><u>With 3-day Free Trial</u></h3>
              <div className="frequency-buttons-container">
                <div className="frequency-buttons">
                  <button
                    className={`frequency-button ${paymentFrequencyBundle === 'monthly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeBundle('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyBundle === 'quarterly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeBundle('quarterly')}
                  >
                    Quarterly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyBundle === 'halfYearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeBundle('halfYearly')}
                  >
                    Half-Yearly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyResume === 'yearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeBundle('yearly')}
                  >
                    Yearly
                  </button>
                </div>
              </div>
            
              <p className="plan-price">
                <span className="original-price">{combinedPrice.original}</span>
                <span className="discount-price">{combinedPrice.discount}</span>
                <span className="plan-price-unit">{combinedPrice.unit}</span>
              </p>
              {combinedPrice.total ? <p className="plan-total-price">{combinedPrice.total}</p> : null}
              <p className="discount-end-text">Discount ends on 31st Aug</p>
             
              <p className="plan-short-description"><strong>The best value for frequent users, offering unlimited access to our advanced AI resume optimization.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Resume&Cover Letter Building</li>
                <li><span className="plan-flex-mark">★</span> Unlimited Resume&Cover Letter Download</li>
                
                <li><span className="plan-flex-mark">★</span>   Professional ATS Keywords Extract</li>
                <li><span className="plan-flex-mark">★</span> Resume Review Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Analysis Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Application Tracker Pro+</li>
                <li><span className="plan-sub-mark">★</span> Unlimited AI Optimization with Advanced AI Model</li>
              </ul>
              <div className="button-container">
                <button className="checkout-and-portal-button" id="checkout-and-portal-button" onClick={handleCheckoutBundle}>
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    )} */}
    {currentSection === 'resume' && (
      <section className="section" id="resume">
        <h1 className="section-heading">STAIRAZ Resume Builder AI</h1>
        <p className="section-content">Unlock the full potential of our AI optimization features with our flexible and affordable plans. We offer multiple options designed to fit your specific needs, all at the lowest possible cost. Choose the plan that's right for you and get started today!</p>
        <section className="section-plan" id="plan-section">
          <div className="plan-container">
            <div className="plan-panel">
              <h2 className="plan-name">Starter</h2>
              <p className="plan-price">Free</p>
              <p className="plan-short-description"><strong>Get started with all the essential resume-building features and a limited AI optimization trial.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Resume Building</li>
                <li><span className="plan-free-mark">✔</span> Free Resume Template</li>
                {/* <li><span className="plan-free-mark">✔</span> Unlimited Resume Download</li> */}
                <li><span className="plan-free-mark">✔</span> Free ATS Keywords Extract</li>
                {/* <li><span className="plan-free-mark">✔</span> Free Resume Review</li> */}
                {/* <li><span className="plan-free-mark">✔</span> Free Job Analysis</li>
                <li><span className="plan-free-mark">✔</span> Free Job Application Tracker</li> */}
              </ul>
            </div>
            <div className="plan-panel">
              <h2 className="plan-name">Subscription</h2>
              <h3><span style={{color:"blue"}}><u>With 7-day Free Trial</u></span></h3>
              <div className="frequency-buttons-container">
                <div className="frequency-buttons">
                  <button
                    className={`frequency-button ${paymentFrequencyResume === 'monthly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChange('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyResume === 'quarterly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChange('quarterly')}
                  >
                    Quarterly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyResume === 'halfYearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChange('halfYearly')}
                  >
                    Half-Yearly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyResume === 'yearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChange('yearly')}
                  >
                    Yearly
                  </button>
                </div>
              </div>
            
              <p className="plan-price">
                <span className="original-price">{pricingResume[paymentFrequencyResume].original}</span>
                <span className="discount-price">${(Math.floor(parseFloat(pricingResume[paymentFrequencyResume].original.replace('$', '')) * parseFloat(pricingResume[paymentFrequencyResume].discount) * 100) / 100).toFixed(2)}</span>
                <span className="plan-price-unit">{pricingResume[paymentFrequencyResume].unit}</span>
              </p>
              {/* {pricingResume[paymentFrequencyResume].total ? <p className="plan-total-price">{pricingResume[paymentFrequencyResume].total}</p> : null} */}
              <p className="discount-end-text">Discount ends on 9/30</p>
             
              <p className="plan-short-description"><strong>The best value for frequent users, offering unlimited access to our advanced AI resume optimization.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Resume Building</li>
                <li><span className="plan-sub-mark">★</span> Guaranteed refund within 7-day after payment</li>
                <li><span className="plan-sub-mark">★</span> Unlimited ATS-Optimized Template</li>
                <li><span className="plan-sub-mark">★</span> Unlimited Resume Download</li>
                <li><span className="plan-sub-mark">★</span> Professional ATS Keywords Extract</li>
                {/* <li><span className="plan-flex-mark">★</span> Resume Review Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Analysis Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Application Tracker Pro+</li> */}
                <li><span className="plan-sub-mark">★</span> Unlimited AI Optimization with Advanced AI Model</li>
              </ul>
              <div className="button-container">
                <button className="checkout-and-portal-button" id="checkout-and-portal-button" onClick={handleCheckoutResume}>
                  Checkout
                </button>
              </div>
            </div>
            {/* <div className="plan-panel">
              <h2 className="plan-name">Flexible Pay</h2>
              <h3><u>Pay What You Use</u></h3>
              <p className="plan-price">
                <span className="original-price">{pricingResume.flexible.original}</span>
                <span className="discount-price">{pricingResume.flexible.discount}</span>
                <span className="plan-price-unit">/100-word revised<span className="label-number">*</span></span>
                
              </p>
              <p className="discount-end-text">Discount ends on 31st Aug</p>
             
              <p className="plan-short-description"><strong>Best for temporary user. Get access to all advanced features, and only pay for the content revised by AI.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Resume Building</li>
                <li><span className="plan-flex-mark">★</span> Unlimited ATS-Optimized Template</li>
                <li><span className="plan-flex-mark">★</span> Unlimited Resume Download</li>
                <li><span className="plan-flex-mark">★</span> Professional ATS Keywords Extract</li>
                <li><span className="plan-flex-mark">★</span> Resume Review Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Analysis Pro+</li>
                <li><span className="plan-flex-mark">★</span> Job Application Tracker Pro+</li>
                <li><span className="plan-flex-mark">★</span> Flexible Payment for AI Optimization with Advanced AI Model</li>
              </ul>
              <div className="detailed-explanation">
              <p><span className="label-number">*</span> The charge is based on the number of words revised by our AI. On average, a well-crafted resume typically requires around 400 words.</p>
            </div>
              <div className="button-container">
                <button className="checkout-and-portal-button" id="checkout-and-portal-button" onClick={handleCheckoutResumeFlex}>
                  Checkout
                </button>
              </div>
            </div> */}
          </div>
        </section>
      </section>
    )}
    {/* {currentSection === 'coverLetter' && (
      <section className="section" id="coverLetter">
        <h1 className="section-heading">STAIRAZ Cover Letter AI</h1>
        <p className="section-content">Unlock the full potential of our AI optimization features with our flexible and affordable plans. We offer multiple options designed to fit your specific needs, all at the lowest possible cost. Choose the plan that's right for you and get started today!</p>
        <section className="section-plan" id="plan-section">
          <div className="plan-container">
            <div className="plan-panel">
              <h2 className="plan-name">Subscription</h2>
              <h3><u>With 3-day Free Trail</u></h3>
              <div className="frequency-buttons-container">
                <div className="frequency-buttons">
                  <button
                    className={`frequency-button ${paymentFrequencyCoverLetter === 'monthly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeCoverLetter('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyCoverLetter === 'quarterly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeCoverLetter('quarterly')}
                  >
                    Quarterly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyCoverLetter === 'halfYearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeCoverLetter('halfYearly')}
                  >
                    Half-Yearly
                  </button>
                  <button
                    className={`frequency-button ${paymentFrequencyCoverLetter === 'yearly' ? 'active' : ''}`}
                    onClick={() => handleFrequencyChangeCoverLetter('yearly')}
                  >
                    Yearly
                  </button>
                </div>
              </div>
            
              <p className="plan-price">
                <span className="original-price">{pricingCoverLetter[paymentFrequencyCoverLetter].original}</span>
                <span className="discount-price">{pricingCoverLetter[paymentFrequencyCoverLetter].discount}</span>
                <span className="plan-price-unit">{pricingCoverLetter[paymentFrequencyCoverLetter].unit}</span>
              </p>
              {pricingCoverLetter[paymentFrequencyCoverLetter].total ? <p className="plan-total-price">{pricingCoverLetter[paymentFrequencyCoverLetter].total}</p> : null}
              <p className="discount-end-text">Discount ends on 31st Aug</p>
              
              <p className="plan-short-description"><strong>The best value for frequent users, offering unlimited access to our advanced AI cover letter optimization.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Cover Letter Building</li>
                <li><span className="plan-flex-mark">★</span> Unlimited Cover Letter Download</li>
                <li><span className="plan-flex-mark">★</span> Professional ATS Keywords Extract</li>
                <li><span className="plan-sub-mark">★</span> Unlimited AI Optimization with Advanced AI Model</li>
              </ul>
              <div className="button-container">
                <button className="checkout-and-portal-button" id="checkout-and-portal-button" onClick={handleCheckoutCoverLetter}>
                  Checkout
                </button>
              </div>
            </div>
            <div className="plan-panel">
              <h2 className="plan-name">Flexible Pay</h2>
              <h3><u>Pay What You Use</u></h3>
              <p className="plan-price">
              <span className="original-price">{pricingCoverLetter.flexible.original}</span>
                <span className="discount-price">{pricingCoverLetter.flexible.discount}</span>
                <span className="plan-price-unit">per 100 words<span className="label-number">*</span></span>
              </p>
              <p className="discount-end-text">Discount ends on 31st Aug</p>
              
              <p className="plan-short-description"><strong>Pay only for what you use with our flexible payment option, ideal for occasional users.</strong></p>
              <ul className="plan-description">
                <li><span className="plan-free-mark">✔</span> Unlimited Cover Letter Building</li>
                <li><span className="plan-flex-mark">★</span> Unlimited Cover Letter Download</li>
                <li><span className="plan-flex-mark">★</span> Professional ATS Keywords Extract</li>
                <li><span className="plan-flex-mark">★</span> Flexible Payment for AI Optimization with Advanced AI Model</li>
              </ul>
              <div className="detailed-explanation">
              <p><span className="label-number">*</span> The charge is based on the number of words revised by our AI. On average, a optimized cover letter typically requires around 600 words.</p>
            </div>
              <div className="button-container">
                <button className="checkout-and-portal-button" id="checkout-and-portal-button" onClick={handleCheckoutCoverLetterFlex}>
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    )} */}
  </Layout>
  );
};

export default AboutUs;
